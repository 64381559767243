@media (max-width: 768px) {
  .App {
    width: 100%;
  }

  body {
    margin: 0;
    font-family: "Futura", "Trebuchet MS", Arial, sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    background-color: rgb(238, 238, 238);
    width: 100%;
  }

  code {
    font-family: "Futura", "Trebuchet MS", Arial, sans-serif;
  }

  @keyframes shake {
    0% {
      transform: translateX(0);
    }

    10%,
    90% {
      transform: translateX(-10px);
    }

    20%,
    80% {
      transform: translateX(10px);
    }

    30%,
    50%,
    70% {
      transform: translateX(-10px);
    }

    40%,
    60% {
      transform: translateX(10px);
    }

    100% {
      transform: translateX(0);
    }
  }

  .error-message {
    color: red;
    position: absolute;
    top: 40%;
    left: 50%;
    transform: translate(-50%, -50%);
    font-weight: bold;
    font-size: 16px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  .password-image {
    position: relative;
    width: 100%;
    height: 100vh;
    object-fit: cover;
  }

  .password-form {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    text-decoration: none;
  }

  .password-input {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    color: white;
    margin-top: 1rem;
    letter-spacing: 0.2rem;

    .text-input {
      display: flex;
      justify-content: center;
      align-items: center;
      border: none;
      border-bottom: 3px solid white;
      background-color: transparent;
      text-decoration: none;
      outline: none;
      color: white;
      text-align: center;
      font-size: 1.5rem;
      width: 12rem;
      letter-spacing: 0.2rem;
      padding: 0.5rem 0;

      &::placeholder {
        color: white;
        font-weight: bold;
        font-size: 1rem;
        text-align: center;
        width: 100%;
      }
    }

    .text-input-error {
      display: flex;
      justify-content: center;
      align-items: center;
      border: none;
      border-bottom: 3px solid white;
      background-color: transparent;
      text-decoration: none;
      outline: none;
      color: white;
      text-align: center;
      font-size: 1.5rem;
      width: 12rem;
      letter-spacing: 0.2rem;
      padding: 0.5rem 0;
      animation: shake 0.6s;

      &::placeholder {
        color: white;
        font-weight: bold;
        font-size: 1rem;
        text-align: center;
        width: 100%;
      }
    }
  }

  .password-button {
    appearance: button;
    backface-visibility: hidden;
    background-color: rgb(131, 59, 2);
    border-radius: 6px;
    border-width: 0;
    box-shadow: rgba(50, 50, 93, 0.1) 0 0 0 1px inset,
      rgba(50, 50, 93, 0.1) 0 2px 5px 0, rgba(0, 0, 0, 0.07) 0 1px 1px 0;
    box-sizing: border-box;
    color: #fff;
    cursor: pointer;
    height: 2rem;
    width: 5rem;
    margin: 1rem;
    outline: none;
    position: relative;
    text-align: center;
    text-transform: none;
    transition: all 0.2s, box-shadow 0.08s ease-in;
    user-select: none;
    touch-action: manipulation;
    font-weight: bold;
    font-size: 1rem;

    &:hover {
      background-color: rgb(90, 115, 144);
    }
  }
}

/* END MEDIA */

body {
  margin: 0;
  font-family: "Futura", "Trebuchet MS", Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: rgb(238, 238, 238);
  width: 100%;
}

code {
  font-family: "Futura", "Trebuchet MS", Arial, sans-serif;
}

@keyframes shake {
  0% {
    transform: translateX(0);
  }

  10%,
  90% {
    transform: translateX(-10px);
  }

  20%,
  80% {
    transform: translateX(10px);
  }

  30%,
  50%,
  70% {
    transform: translateX(-10px);
  }

  40%,
  60% {
    transform: translateX(10px);
  }

  100% {
    transform: translateX(0);
  }
}

.error-message {
  color: red;
  position: absolute;
  top: 40%;
  left: 50%;
  transform: translate(-50%, -50%);
  font-weight: bold;
  font-size: 16px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.password-image {
  position: relative;
  width: 100%;
  height: 100vh;
}

.password-form {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  text-decoration: none;
}

.password-input {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  color: white;
  margin-top: 1rem;
  letter-spacing: 0.2rem;

  .text-input {
    display: flex;
    justify-content: center;
    align-items: center;
    border: none;
    border-bottom: 3px solid white;
    background-color: transparent;
    text-decoration: none;
    outline: none;
    color: white;
    text-align: center;
    font-size: 1.5rem;
    width: 12rem;
    letter-spacing: 0.2rem;
    padding: 0.5rem 0;

    &::placeholder {
      color: white;
      font-weight: bold;
      font-size: 1rem;
      text-align: center;
      width: 100%;
    }
  }

  .text-input-error {
    display: flex;
    justify-content: center;
    align-items: center;
    border: none;
    border-bottom: 3px solid white;
    background-color: transparent;
    text-decoration: none;
    outline: none;
    color: white;
    text-align: center;
    font-size: 1.5rem;
    width: 12rem;
    letter-spacing: 0.2rem;
    padding: 0.5rem 0;
    animation: shake 0.6s;

    &::placeholder {
      color: white;
      font-weight: bold;
      font-size: 1rem;
      text-align: center;
      width: 100%;
    }
  }
}

.password-button {
  appearance: button;
  backface-visibility: hidden;
  background-color: rgb(131, 59, 2);
  border-radius: 6px;
  border-width: 0;
  box-shadow: rgba(50, 50, 93, 0.1) 0 0 0 1px inset,
    rgba(50, 50, 93, 0.1) 0 2px 5px 0, rgba(0, 0, 0, 0.07) 0 1px 1px 0;
  box-sizing: border-box;
  color: #fff;
  cursor: pointer;
  height: 2rem;
  width: 5rem;
  margin: 1rem;
  outline: none;
  position: relative;
  text-align: center;
  text-transform: none;
  transition: all 0.2s, box-shadow 0.08s ease-in;
  user-select: none;
  touch-action: manipulation;
  font-weight: bold;
  font-size: 1rem;

  &:hover {
    background-color: rgb(90, 115, 144);
  }
}